/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';
import Head from 'next/head';
import Router from 'next/router';
import App, { AppProps, AppContext, AppInitialProps } from 'next/app';
import { useSSR } from 'react-i18next';

import { pageview } from '~/lib/googleAnalytics';
import ErrorBoundary from '~/components/ErrorBoundary/ErrorBoundary';
import i18next, { init as init18n } from '../i18n';

import 'app.scss';

const TtApp = ({ Component, pageProps }: AppProps): JSX.Element => {
  useEffect(() => {
    const handleRouteChange = (url: string) => pageview(url);

    const handleLanguageChange = (language: string) => {
      if (typeof window === 'undefined') {
        return;
      }

      const htmlLanguage = document.documentElement.lang;

      if (htmlLanguage !== language) {
        document.documentElement.lang = language;
      }
    };

    Router.events.on('routeChangeComplete', handleRouteChange);
    i18next.on('languageChanged', handleLanguageChange);

    return (): void => {
      i18next.off('languageChanged', handleLanguageChange);
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  init18n();

  if (pageProps.ssrI18Next) {
    const { store, language } = pageProps.ssrI18Next;
    useSSR(store, language);
  }

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      </Head>

      <ErrorBoundary>
        <Component {...pageProps} />
      </ErrorBoundary>
    </>
  );
};

// TODO: getServerSideProps() for custom App coming at some point: https://github.com/zeit/next.js/discussions/10949
TtApp.getInitialProps = async (appContext: AppContext): Promise<AppInitialProps> => {
  const appProps: AppInitialProps = await App.getInitialProps(appContext);
  const { pageProps } = appProps;

  return {
    pageProps: {
      ...pageProps,
      ssrI18Next: {
        store: i18next.isInitialized ? i18next.services.resourceStore.data : {},
        language: i18next.options.lng,
      },
    },
  };
};

export default TtApp;
