/* eslint-disable react/no-danger */
const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID;

/**
 * Official Next.js + GA example: https://github.com/vercel/next.js/tree/canary/examples/with-google-analytics
 */
type Params = {
  action: string;
  category: string;
  label: string;
  value: string;
};

export const pageview = (url: string): void => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  });
};

export const event = ({ action, category, label, value }: Params): void => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
  });
};

export const GoogleAnalyticsScript: React.FC = () => {
  if (!GA_TRACKING_ID) {
    return null;
  }

  return (
    <>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />

      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
    </>
  );
};
