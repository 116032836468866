import { NextRouter, withRouter } from 'next/router';
import React, { ErrorInfo } from 'react';

import { logger } from '~/lib/services/Logger';
import { parseQueryParam } from '~/lib/utils/utils';

type WithRouterProps = { router: NextRouter };

class ErrorBoundary extends React.Component<WithRouterProps> {
  componentDidCatch(error: Error, info: ErrorInfo): void {
    const { router } = this.props;
    const { deliveryId, slug } = router.query;

    logger.captureError({
      error,
      params: { info },
      session: {
        deliveryId: parseQueryParam(deliveryId),
        slug: parseQueryParam(slug),
      },
    });
  }

  render(): React.ReactNode {
    const { children } = this.props;
    return children;
  }
}

export default withRouter(ErrorBoundary);
