import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import BackendHttp from 'i18next-http-backend';
import BackendFs from 'i18next-fs-backend';

import localazyMetadata from '~/lib/generated/languages';

const isServer = typeof window === 'undefined';
const supportedLanguages = localazyMetadata.languages.map((l) => l.language);

export function init(): void {
  i18next
    .use(isServer ? BackendFs : BackendHttp)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      initImmediate: false, // !isServer
      fallbackLng: 'en',
      supportedLngs: supportedLanguages,
      ns: ['common', 'status'],
      defaultNS: 'common',
      preload: ['cs', 'en'],
      backend: {
        loadPath: isServer ? './public/locales/{{lng}}/{{ns}}.json' : '/locales/{{lng}}/{{ns}}.json',
      },
      debug: false,
      interpolation: {
        escapeValue: false, // React escapes by default
      },
      react: {
        useSuspense: false, // !isServer, // suspense not compatible with SSR
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'],
      },
    });
}

export default i18next;
